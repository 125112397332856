/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    strong: "strong",
    br: "br",
    h3: "h3",
    ol: "ol",
    li: "li",
    a: "a",
    h4: "h4",
    pre: "pre",
    code: "code",
    ul: "ul"
  }, _provideComponents(), props.components), {ContentFilters, Admonition, FilteredContent, RequestParameters, RequestSample, ResponseBody, ResponseSample} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  if (!ContentFilters) _missingMdxReference("ContentFilters", true);
  if (!FilteredContent) _missingMdxReference("FilteredContent", true);
  if (!RequestParameters) _missingMdxReference("RequestParameters", true);
  if (!RequestSample) _missingMdxReference("RequestSample", true);
  if (!ResponseBody) _missingMdxReference("ResponseBody", true);
  if (!ResponseSample) _missingMdxReference("ResponseSample", true);
  return React.createElement(React.Fragment, null, React.createElement(ContentFilters, {
    filters: ["Request parameters", "Request samples", "Response body", "Response sample"]
  }), "\n", React.createElement(_components.p, null, "If a customer requests a refund and the payment is still in an open batch, the merchant can use their POS to look up the bank transfer payment and cancel the transaction."), "\n", React.createElement(_components.p, null, "To find the paymentId of the bank transfer payment that the merchant wants to reverse, they can retrieve a list of all the bank transfer payments that meet the search criteria that the merchant provides. They can then find the transaction that they want in the list of returned results."), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, "If the merchant runs a referenced refund on a bank transfer payment that is in an open batch, our gateway automatically reverses the payment.")), "\n", React.createElement(_components.h2, null, "Integration steps"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Step 1."), " (Optional) List bank transfer payments.", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Step 2."), " Reverse the bank transfer payment."), "\n", React.createElement(_components.h2, null, "Before you begin"), "\n", React.createElement(_components.h3, null, "Bearer tokens"), "\n", React.createElement(_components.p, null, "Use our Identity Service to generate a Bearer token to include in the header of your requests. To generate your Bearer token, complete the following steps:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Include your API key in the x-api-key parameter in the header of a POST request."), "\n", React.createElement(_components.li, null, "Send your request to ", React.createElement(_components.a, {
    href: "https://identity.payroc.com/authorize"
  }, "https://identity.payroc.com/authorize"), "."), "\n"), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, "You need to generate a new Bearer token before the previous Bearer token expires.")), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h4, null, "Example request"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl --location --request POST  'https://identity.payroc.com/authorize' --header 'x-api-key: <api key>'\n"))), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h4, null, "Example response"), React.createElement(_components.p, null, "If your request is successful, we return a response that contains your Bearer token, information about its scope, and when it expires."), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n\"access_token\": \"eyJhbGc....adQssw5c\",\n\"expires_in\": 3600,\n\"scope\": \"service_a service_b\",\n\"token_type\": \"Bearer\"\n}\n"))), "\n", React.createElement(_components.h3, null, "Headers"), "\n", React.createElement(_components.p, null, "To create the header of each GET request, you must include the following parameters:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Content-Type:"), " Include application/json as the value for this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Authorization:"), " Include your Bearer token in this parameter."), "\n"), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl\n-H \"Content-Type: application/json\"\n-H \"Authorization: <Bearer token>\"\n"))), "\n", React.createElement(_components.p, null, "To create the header of each POST request, you must include the following parameters:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Content-Type:"), " Include application/json as the value for this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Authorization:"), " Include your Bearer token in this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Idempotency-Key:"), " Include a UUID v4 to make the request idempotent."), "\n"), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl\n-H \"Content-Type: application/json\"\n-H \"Authorization: <Bearer token>\"\n-H \"Idempotency-Key: <UUID v4>\"\n"))), "\n", React.createElement(_components.h3, null, "Errors"), "\n", React.createElement(_components.p, null, "If your request is unsuccessful, we return an error. For more information about errors, see ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/errors"
  }, "Errors"), "."), "\n", React.createElement(_components.h2, null, "Step 1. (Optional) List bank transfer payments"), "\n", React.createElement(_components.p, null, "To retrieve a list of bank transfer payments, send a GET request to our Bank Transfer Payments endpoint."), "\n", React.createElement(_components.p, null, "Use our filters to narrow down the search results."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Test endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/bank-transfer-payments"
  }, "https://api.uat.payroc.com/v1/bank-transfer-payments"), React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Production endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.payroc.com/v1/bank-transfer-payments"
  }, "https://api.payroc.com/v1/bank-transfer-payments")), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(_components.h3, null, "Request parameters"), React.createElement(_components.p, null, "To create the body of your request, use the following parameters:"), React.createElement(RequestParameters, {
    operationId: "listBankTransferPayments"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h3, null, "Example request"), React.createElement(RequestSample, {
    operationId: "listBankTransferPayments"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response body"
  }, React.createElement(_components.h3, null, "Response fields"), React.createElement(_components.p, null, "If your request is successful, we retrieve a list of the bank transfer payments and return a response. The response contains the following fields:"), React.createElement(ResponseBody, {
    operationId: "listBankTransferPayments"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h3, null, "Example response"), React.createElement(ResponseSample, {
    operationId: "listBankTransferPayments"
  })), "\n", React.createElement(_components.h2, null, "Step 2. Reverse the bank transfer payment"), "\n", React.createElement(_components.p, null, "To reverse a bank transfer payment, send a POST request to the Bank Transfer Payments endpoint.", React.createElement(_components.br), "\n", "If the merchant wants to reverse only part of the transaction, send the amount that they want to reverse in the ", React.createElement(_components.strong, null, "amount"), " parameter."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Test endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/bank-transfer-payments/%7BpaymentId%7D/reverse"
  }, "https://api.uat.payroc.com/v1/bank-transfer-payments/{paymentId}/reverse"), React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Production endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.payroc.com/v1/bank-transfer-payments/%7BpaymentId%7D/reverse"
  }, "https://api.payroc.com/v1/bank-transfer-payments/{paymentId}/reverse")), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(_components.h3, null, "Request parameters"), React.createElement(_components.p, null, "To create the body of your request, use the following parameters:"), React.createElement(RequestParameters, {
    operationId: "reverseBankTransferPayment"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h3, null, "Example request"), React.createElement(RequestSample, {
    operationId: "reverseBankTransferPayment"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response body"
  }, React.createElement(_components.h3, null, "Response fields"), React.createElement(_components.p, null, "If your request is successful, we reverse the bank transfer payment and return a response. The response contains the following fields:"), React.createElement(ResponseBody, {
    operationId: "reverseBankTransferPayment"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h3, null, "Example response"), React.createElement(ResponseSample, {
    operationId: "reverseBankTransferPayment"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
